import { useState, useEffect, useContext, ChangeEvent, useCallback } from "react";
import { FiUpload, FiDownload, FiMessageSquare } from "react-icons/fi";
import Select from "react-select";
import { toast } from "react-toastify";

import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/apiClient";
import { Button } from "../ui/Button";
import styles from "./styles.module.scss";

interface Option {
    value: string;
    label: string;
}

interface SideBarProps {
    onClose: () => void;
}

export default function SideBar({ onClose }: SideBarProps) {
    const { signOut, user, isAuthenticated } = useContext(AuthContext);
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [isHovered, setIsHovered] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [scrollHorizontal, setScrollHorizontal] = useState(window.scrollX);
    const [loading, setLoading] = useState(false);
    const [isHoverSideBar, setIsHoverSideBar] = useState(true);

    const ALLOWED_FILE_TYPES = [
        "image/bmp",
        "image/jpg",
        "image/bitmap",
        "image/jpeg",
        "application/pdf",
        "text/plain",
        "image/png",
        "text/xml",
        "application/rtf",
    ];

    const MAX_FILE_SIZE = 10485760;

    const heightVert = () => {
        const elemento = document.documentElement;
        const scrollHabilitado = elemento.scrollWidth > elemento.clientWidth;
        if (scrollHabilitado) {
            return window.scrollY - 10;
        } else {
            return window.scrollY;
        }
    };
    const [scrollVertical, setScrollVertical] = useState(heightVert);

    useEffect(() => {
        const handleScroll = () => {
            setScrollHorizontal(window.scrollX);
            const elemento = document.documentElement;
            const scrollHabilitado = elemento.scrollWidth > elemento.clientWidth;
            if (scrollHabilitado) {
                setScrollVertical(window.scrollY - 10);
            } else {
                setScrollVertical(window.scrollY);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleMouseEnter = (): void => {
        setIsHovered(true);
    };

    const handleMouseLeave = (): void => {
        setIsHovered(false);
    };

    const refreshFiles = useCallback(async () => {
        setIsHoverSideBar(true);
        setIsDisabled(true);
        await api
            .get(`/listfiles?clienteId=${user?.clienteId}&usuarioId=${user?.id}`)
            .then((response) => {
                createOptionsList(response.data.files);
                setIsDisabled(false);
            })
            .catch((err) => {
                toast.error("Falha ao buscar lista de arquivos!");
                console.log("Falha ao buscar lista de arquivos" + err);
                if (err.code && err.code === "401") {
                    signOut();
                }
            });
    }, [user, signOut]);

    useEffect(() => {
        if (isAuthenticated && options.length <= 0) {
            refreshFiles();
        }
    }, [isAuthenticated, refreshFiles, options.length]);

    const handleChangeFile = (selectedOption: Option | null) => {
        setIsHoverSideBar(true);
        setSelectedOption(selectedOption);
        downloadFile(selectedOption?.value as string);
    };

    const handleDownload = () => {
        setIsHoverSideBar(true);
        downloadFile(selectedOption?.value as string);
    };

    function downloadFile(fileName: string) {
        if (!fileName) {
            return;
        }
        if (fileName !== "-1") {
            if (fileName.toLowerCase().endsWith(".pdf")) {
                window.open(fileName, "_blank");
            } else {
                fetch(fileName)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = fileName.split("/").pop() ?? "downloaded_file";
                        link.style.display = "none";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch((error) => console.error("Erro ao baixar o arquivo:", error));
            }
        }
    }

    function createOptionsList(list: string[]) {
        const options: Option[] = list.map((item) => {
            let fileName = item.substr(item.lastIndexOf("/") + 1);
            fileName = fileName.replaceAll("_-_", " ");
            fileName = fileName.replaceAll("_.._", ":");
            fileName = fileName.replaceAll("-.-", "/");
            return {
                value: item,
                label: fileName,
            };
        });
        setOptions(options);
    }

    async function uploadFiles(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];

        if (!file) {
            return;
        }
        if (file.type === "application/x-msdownload" || !file.type) {
            toast.warning("tipo de arquivo não permitido!");
            return;
        }

        if (!ALLOWED_FILE_TYPES.includes(file.type) || !file.type) {
            toast.warning("tipo de arquivo não permitido!");
            return;
        }
        if (file.size > MAX_FILE_SIZE) {
            toast.warning("Arquivos acima de 10 MB não é permitido!");
            return;
        }
        const usuarioId = user?.id ?? "";
        const clienteId = user?.clienteId ?? "";
        const data = new FormData();
        data.append("usuarioId", usuarioId);
        data.append("clienteId", clienteId);
        data.append("file", file);

        setLoading(true);
        await api
            .post("/upload", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(() => {
                setLoading(false);
                toast.success("Upload do arquivo realizado com sucesso!");
                refreshFiles();
            })
            .catch((err) => {
                setLoading(false);
                toast.error("Falha no upload do arquivo!");
                console.log("Falha no upload do arquivo. " + err);
            });
    }

    const customStyles = {
        placeholder: (provided: any) => ({
            ...provided,
            color: isHovered ? `var(--primaryHLColor)` : `var(--fontColor)`, // Altere a cor do placeholder
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }),
        control: (provided: any) => ({
            ...provided,
            backgroundColor: `var(--primaryBGColor)`, // Altere a cor de fundo da caixa de seleção
            border: "none", // Remover a borda do controle
            boxShadow: `0 0 3px var(--primaryINColor)`,
            color: `var(--fontColor)`,
            cursor: "pointer",
            height: "35px",
            "&:hover": {
                placeholder: {
                    color: "blue",
                },
            },
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            color: state.isFocused ? "red" : "black",
            "&:hover": {
                color: `var(--primaryHLColor)`,
            },
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: `var(--secundaryBGColor)`, // Altere a cor de fundo da lista suspensa
            color: `var(--fontColor)`,
            cursor: "pointer",
            "&:hover": {
                borderColor: `var(--primaryINColor)`, // Altere a cor da borda ao passar o mouse sobre a caixa de seleção
                color: `var(--primaryHLColor)`,
            },
        }),
        option: (provided: any) => ({
            ...provided,
            backgroundColor: `var(--primaryBGColor)`, // Altere a cor de fundo das opções selecionadas e não selecionadas
            color: `var(--fontColor)`, // Altere a cor do texto das opções selecionadas e não selecionadas
            cursor: "pointer",
            "&:hover": {
                borderColor: `var(--primaryINColor)`, // Altere a cor da borda ao passar o mouse sobre a caixa de seleção
                color: `var(--primaryHLColor)`,
            },
        }),
        input: (provided: any) => ({
            ...provided,
            backgroundColor: "transparent", // Altere a cor de fundo das opções selecionadas e não selecionadas
            color: `var(--fontColor)`, // Altere a cor do texto das opções selecionadas e não selecionadas
            cursor: "pointer",
            borderColor: `var(--primaryINColor)`,
            "&:hover": {
                borderColor: `var(--primaryINColor)`, // Altere a cor da borda ao passar o mouse sobre a caixa de seleção
                color: `var(--primaryHLColor)`,
            },
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: `var(--primaryINColor)`, // Altere a cor do texto do componente Select
            "&:hover": {
                color: `var(--primaryHLColor)`,
            },
        }),
    };

    const handleMouseOverSideBar = () => {
        setIsHoverSideBar(true);
    };

    const handleMouseOutSideBar = () => {
        setIsHoverSideBar(false);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!isHoverSideBar) {
                onClose();
            }
        }, 1000 * 3);

        return () => clearTimeout(timeout);
    }, [isHoverSideBar, onClose]);

    const handleOpenCallCenter = () => {
        window.open("http://atendimento.omegasistemas.net.br", "_blank");
    };

    return (
        <header
            className={styles.headerContainer}
            style={{
                left: `calc(100% - 317px + ${scrollHorizontal}px)`,
                top: `calc(100% - 420px + ${scrollVertical}px)`,
            }}
            onMouseOver={handleMouseOverSideBar}
            onMouseOut={handleMouseOutSideBar}
        >
            <div className={styles.headerContent}>
                <nav className={styles.menuNav}>
                    <div className={styles.contentTop} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <FiDownload className={styles.iconDownload} size={24} color="#FFF" onClick={handleDownload} />
                        <Select
                            className={styles.boxFiles}
                            styles={customStyles}
                            options={options}
                            placeholder={isDisabled ? "Carregando Arquivos..." : "Download de Arquivo"}
                            onChange={handleChangeFile}
                            isDisabled={isDisabled}
                        />
                    </div>
                    <div className={styles.contentTop}>
                        <label className={styles.labelInputFile}>
                            <FiUpload className={styles.iconDownload} size={24} color="#FFF" />
                            <span className={styles.labelUpload}>Upload de Arquivo</span>
                            <input
                                className={styles.inputFile}
                                type="file"
                                accept={ALLOWED_FILE_TYPES.toString()}
                                onChange={uploadFiles}
                            />
                        </label>
                    </div>
                    <div className={styles.btnCallCenter}>
                        <label className={styles.labelLogout} onClick={handleOpenCallCenter}>
                            <FiMessageSquare className={styles.iconCallCenter} />
                            <span>Atendimento</span>
                        </label>
                    </div>
                    <div className={styles.btnLogout}>
                        <label className={styles.labelLogout} onClick={signOut}>
                            {loading ? (
                                <Button type="submit" loading={loading}>
                                    Uploading...
                                </Button>
                            ) : (
                                <span>Logout</span>
                            )}
                        </label>
                    </div>
                </nav>
            </div>
        </header>
    );
}
