import { Routes, Route } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Private from "./private";

function RoutesApp() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route
                path="/dashboard"
                element={
                    <Private>
                        <Dashboard />
                    </Private>
                }
            />
            <Route
                path="*"
                element={
                    <Private>
                        <Dashboard />
                    </Private>
                }
            />
        </Routes>
    );
}

export default RoutesApp;
