import Axios, { AxiosInstance } from "axios";

let urlBase = "";

const getAxios = (): AxiosInstance => {
    return Axios.create({});
};

const getUrlBase = (url: string = "", forceUrl: boolean = false): string => {
    if (url.includes("teste.omegaeducacional")) {
        urlBase = "https://api.teste.omegaeducacional.com/hotsite";
    } else {
        if (process.env.NODE_ENV === "development") {
            urlBase = "https://api.teste.omegaeducacional.com";
        }

        if (process.env.NODE_ENV === "test") {
            urlBase = "https://api.teste.omegaeducacional.com";
        }

        if (process.env.NODE_ENV === "production") {
            urlBase = "https://api.omegaeducacional.com";
        }

        urlBase += "/hotsite";
    }

    if (forceUrl) {
        urlBase = url;
    }

    return urlBase;
};

export const getHostName = async (gclienteId: number): Promise<string> => {
    getUrlBase();
    const response = await getAxios().get(urlBase + "/apps", {
        params: {
            gclienteId: gclienteId,
        },
    });
    return response?.data?.hotsite?.url;
};

export const getData = async (path: string, params: any = undefined, forceUrl: boolean = false): Promise<any> => {
    getUrlBase(params && params.url, forceUrl);
    const { data } = await getAxios().get(urlBase + path, { params });
    return data.data;
};
