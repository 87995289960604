import { useState, useEffect, useContext } from "react";

import { destroyCookie } from "nookies";

import PopUpMenu from "../../components/PopUpMenu";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "./styles.module.scss";

export default function Dashboard() {
    const [url, setUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const fileToken = sessionStorage.getItem("@auth.fileToken");

    // caracter = -> %3D
    // caracter ; -> %3B
    useEffect(() => {
        setUrl(
            `/${user?.siteName}/logon.html?app=${user?.application}&useApp=false&args=VIRTUAL%3DS%3BU%3D${user?.id}%3BLAYOUT%3D3%3BP%3D${fileToken}`,
        );
    }, [user?.application, user?.id, user?.siteName, fileToken]);

    const handleLoad = () => {
        setLoading(true);
        setTimeout(function () {
            setLoading(false);
            destroyCookie(null, "@auth.usr");
            destroyCookie(null, "@auth.pwd");
            destroyCookie(null, "@auth.app");
            destroyCookie(null, "@auth.arg");
        }, 1000 * 20);
    };

    return (
        <div className={styles.container}>
            <PopUpMenu />
            <div className={styles.frame}>
                {loading && (
                    <div id="dvLoading" className={styles.loading}>
                        <img
                            src="https://omg.mt.ed.omegaeducacional.com/view_padrao/img/loading.gif"
                            id="imgLoading"
                            width="87"
                            height="69"
                        />
                        <span> Carregando Aguarde... </span>
                    </div>
                )}
                <iframe
                    id="frame-modulo"
                    src={url}
                    sandbox="allow-same-origin allow-scripts allow-modals allow-downloads"
                    width="100%"
                    height="100%"
                    title={user?.moduloName}
                    onLoad={handleLoad}
                ></iframe>
            </div>
        </div>
    );
}
