import { useState, useEffect } from "react";
import { FiMenu, FiX } from "react-icons/fi";

import SideBar from "../SideBar";
import styles from "./styles.module.scss";

export default function PopUpMenu() {
    const [isVisibleHeader, setIsVisibleHeader] = useState(false);
    const [scrollHorizontal, setScrollHorizontal] = useState(window.scrollX);
    const [scrollVertical, setScrollVertical] = useState(window.scrollY);

    useEffect(() => {
        const handleScroll = () => {
            setScrollHorizontal(window.scrollX);
            const elemento = document.documentElement;
            const scrollHabilitado = elemento.scrollWidth > elemento.clientWidth;
            if (scrollHabilitado) {
                setScrollVertical(window.scrollY - 10);
            } else {
                setScrollVertical(window.scrollY);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function toggleMenu() {
        setIsVisibleHeader(!isVisibleHeader);
    }

    const onCloseSideBar = () => {
        setIsVisibleHeader(false);
    };

    return (
        <>
            {isVisibleHeader ? <SideBar onClose={onCloseSideBar} /> : <></>}
            <header
                className={styles.headerContainer}
                style={{
                    marginLeft: `calc(100% - 53px + ${scrollHorizontal}px)`,
                    top: `calc(100% - 53px + ${scrollVertical}px)`,
                }}
            >
                <div className={styles.headerContent}>
                    <nav className={styles.menuNav}>
                        <button className={styles.btnMenu} onClick={toggleMenu}>
                            {isVisibleHeader ? (
                                <FiX color={`var(--fontColor)`} size={24} />
                            ) : (
                                <FiMenu color={`var(--fontColor)`} size={24} />
                            )}
                        </button>
                    </nav>
                </div>
            </header>
        </>
    );
}
