import { redirect } from "react-router-dom";

import axios, { AxiosError } from "axios";

import { AuthTokenError } from "./errors/AuthTokenError";

export function setupAPIClient() {
    const token = sessionStorage.getItem("@auth.token");

    const api = axios.create({
        baseURL: process.env.REACT_APP_DEFAULT_SERVER_API_URL,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        (error: AxiosError) => {
            if (error.response && error.response.status === 401) {
                // qualquer erro 401 (nao autorizado) devemos deslogar o usuario
                if (typeof window !== "undefined") {
                    // Chamar a funçao para deslogar o usuario
                    sessionStorage.removeItem("@auth.token");
                    sessionStorage.removeItem("@auth.fileToken");
                    return redirect("/");
                } else {
                    return Promise.reject(new AuthTokenError());
                }
            }

            return Promise.reject(error);
        },
    );

    return api;
}
