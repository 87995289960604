import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";

import Layout from "../../components/Layout";
import { Button } from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "./styles.module.scss";

export default function Login() {
    const location = useLocation();
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [clienteId, setClienteId] = useState("");
    const [moduloName, setModuloName] = useState("");
    const { signIn } = useContext(AuthContext);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paramModulo = queryParams.get("module") as string;
        const paramCliente = queryParams.get("cliente") as string;

        if (paramModulo && paramModulo !== "") {
            setModuloName(paramModulo);
        }

        if (paramCliente && paramCliente !== "") {
            setClienteId(paramCliente);
        }

        if ((!paramModulo || paramModulo === "") && (!paramCliente || paramCliente === "")) {
            axios
                .get("/identificar")
                .then((response) => {
                    const { modulo, cliente } = response.data;
                    if (modulo) {
                        setModuloName(modulo);
                    }
                    if (cliente) {
                        setClienteId(cliente);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from /identificar:", error);
                });
        }
    }, [location.search]);

    const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLogin(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        if (login === "" || password === "") {
            toast.error("Preencha todos os campos");
            setLoading(false);
            return;
        }

        if (!clienteId) {
            toast.error("Não foi possível identificar o cliente");
            setLoading(false);
            return;
        }

        if (!moduloName) {
            toast.error("Não foi possível identificar o sistema");
            setLoading(false);
            return;
        }

        const data = {
            login,
            password,
            clienteId,
            moduloName,
        };

        await signIn(data);
        setLoading(false);
    };

    const getModuleName = (moduloName: string) => {
        switch (moduloName) {
            case "educacao":
                return "Escola";
            case "merenda":
                return "Merenda";
            case "veiculo":
                return "Veículo";
            case "biblioteca":
                return "Biblioteca";
            case "financeiro":
                return "Financeiro";
            case "sgfem":
                return "SGFEM";
            case "rh":
                return "R.H.";
            default:
                return "Omega Virtual";
        }
    };

    return (
        <Layout clienteId={clienteId}>
            <div className={styles.containerCenter}>
                <div className={styles.login}>
                    <div className={styles.modulo}>
                        <h1>{getModuleName(moduloName)}</h1>
                    </div>

                    <form onSubmit={handleFormSubmit}>
                        <Input placeholder="Digite o login" type="text" value={login} onChange={handleLoginChange} />

                        <Input
                            placeholder="Digite a senha"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                        />

                        <Button type="submit" loading={loading}>
                            Acessar
                        </Button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}
