import React, { useState, useEffect, useCallback } from "react";

import { AxiosError } from "axios";
import { addDays, format, isAfter, parse } from "date-fns";
import localforage from "localforage";

import { createTheme } from "@material-ui/core/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

import bg from "../../assets/bg.4b59c566.png";
import Header from "../../components/Header";
import { getData, getHostName } from "../../services/apiHotsite";

interface ImageData {
    imagemBytes?: Blob;
    expiresIn?: string;
}

interface IInfo {
    ATIVA_HOTSITE: string;
    GCLIENTE_ID: number;
    GCLIENTE_NOME: string;
    STATUS: number;
}

const CDN_URL = "https://cdn.omegaeducacional.com/clientes/logo/";

const theme = createTheme({
    palette: {
        primary: {
            main: "#3b853e",
        },
        secondary: {
            light: "#0066ff",
            main: "#0044ff",
            contrastText: "#ffcc00",
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: "'Exo 2', Roboto, Verdana, Helvetica, Arial, sans-serif",
    },
});

interface LayoutProps {
    children: React.ReactNode;
    clienteId: string;
}

const Layout: React.FC<LayoutProps> = ({ children, clienteId }) => {
    const [loading, setLoading] = useState(false);
    const [imagemCliente, setImagemCliente] = useState<string | undefined>(undefined);
    const [siteTitle, setSiteTitle] = useState<string>("");
    const [siteSubTitle, setSiteSubTitle] = useState<string>("");
    const [hostName, setHostName] = useState<string>("");
    const [info, setInfo] = useState<IInfo>();
    const [error, setError] = useState<AxiosError>();

    const loadHostName = useCallback(async () => {
        const host = await getHostName(Number(clienteId));
        setHostName(host);
    }, [clienteId]);

    useEffect(() => {
        if (clienteId) {
            loadHostName();
        }
    }, [clienteId, loadHostName]);

    const loadInfo = useCallback(
        async (url: string) => {
            const data = await getData(url, { url: hostName }).catch((error) => {
                setError(error);
            });
            setInfo(data);
        },
        [hostName],
    );

    useEffect(() => {
        if (hostName) {
            loadInfo("/info");
        }
    }, [hostName, loadInfo]);

    const getImageCliente = useCallback(async () => {
        const imageName = hostName.toString().replace(".omegaeducacional.com", "");
        const today = new Date();
        let imagemBytes: Blob | null = null;
        let dataImage: ImageData | null = null;
        let expiresIn: string | undefined = new Date().toString();

        const getImage = async (name: string, remote: boolean = false): Promise<Blob> => {
            const v = Date.now();
            const response = await fetch(remote ? `${CDN_URL}${name}.png?v=${v}` : `/upload/${name}?v=${v}`);
            const dados = await response.blob();
            return dados;
        };

        const createImageUrl = (dados: Blob): string => {
            if (URL.createObjectURL) {
                return URL.createObjectURL(dados);
            } else {
                return "image";
            }
        };

        if (process.env.NODE_ENV !== "test") {
            dataImage = await localforage.getItem<ImageData>(`image-cliente-${imageName}`);
            if (dataImage) {
                imagemBytes = dataImage?.imagemBytes || null;
                expiresIn = dataImage?.expiresIn;
            }
        }

        if (expiresIn) {
            const expiresInDate: Date = parse(expiresIn, "dd/MM/yyyy", today);
            if (!imagemBytes || isAfter(today, expiresInDate)) {
                imagemBytes = await getImage(imageName, true);
                if (!imagemBytes) {
                    imagemBytes = await getImage("logo-cliente.png", false);
                }
                if (!imagemBytes) {
                    imagemBytes = null;
                } else {
                    if (process.env.NODE_ENV !== "test") {
                        const expiresInNew = format(addDays(today, 7), "dd/MM/yyyy");
                        await localforage.setItem(`image-cliente-${imageName}`, {
                            imagemBytes,
                            expiresIn: expiresInNew,
                        });
                    }
                }
            }
        }

        let urlImage: string | undefined = undefined;
        if (imagemBytes) {
            urlImage = createImageUrl(imagemBytes);
        }

        setImagemCliente(urlImage);
    }, [hostName]);

    useEffect(() => {
        if (clienteId && hostName) {
            setLoading(true);
            getImageCliente();
        }
    }, [getImageCliente, clienteId, hostName]);

    useEffect(() => {
        if (info) {
            splitTitles(info);
            setLoading(false);
        } else if (error) {
            setLoading(false);
        }
    }, [info, error]);

    const splitTitles = (data: IInfo) => {
        if (data?.GCLIENTE_NOME) {
            const splitTitle = data.GCLIENTE_NOME.split(" - ");
            if (splitTitle.length > 1) {
                setSiteTitle(splitTitle[0]);
                setSiteSubTitle(`${splitTitle[1]} - ${splitTitle[2] || ""}`);
            } else {
                setSiteTitle(data.GCLIENTE_NOME);
                setSiteSubTitle("");
            }
        } else {
            setSiteTitle("");
            setSiteSubTitle("");
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Header siteTitle={siteTitle} siteSubTitle={siteSubTitle} loading={loading} imagem={imagemCliente} />
            <link href={"https://cdn.omegaeducacional.com/fonts/exo2/exo2.css"} rel="stylesheet" />
            <style>
                {`html {
          background-color: ${theme.palette.primary.main};
          background-image: url(${bg})
        }`}
            </style>
            <main>{children ? children : null}</main>
        </ThemeProvider>
    );
};

export default Layout;
