import Skeleton from "@material-ui/lab/Skeleton";

import styles from "./styles.module.scss";

interface HeaderProps {
    siteTitle: string;
    siteSubTitle: string;
    imagem: string | undefined;
    loading: boolean;
}

const Header: React.FC<HeaderProps> = ({ siteTitle, siteSubTitle, imagem, loading }) => {
    return (
        <header className={styles.header}>
            <div className={styles.headerContainer}>
                <h1>
                    <a href="/">
                        <div className={styles.containerImage}>
                            {loading ? (
                                <Skeleton animation="wave" variant="circle" height={"100%"} width={"100%"} />
                            ) : (
                                <img src={imagem} alt="logo cliente" className={styles.imgCliente} />
                            )}
                        </div>

                        <div className={styles.containerTitles}>
                            {loading ? (
                                <Skeleton
                                    animation="wave"
                                    height={65}
                                    width={"70%"}
                                    style={{ display: "inline-block" }}
                                />
                            ) : (
                                <>
                                    <span className={styles.title}>{siteTitle}</span>
                                    <span className={styles.subTitle}>{siteSubTitle}</span>
                                </>
                            )}
                        </div>
                    </a>
                </h1>
            </div>
        </header>
    );
};

Header.defaultProps = {
    siteTitle: "",
    siteSubTitle: "",
};

export default Header;
